import { Injectable } from "@angular/core";

export class ContactInfo {
    'First name' = '';
    'Last name' = '';
    'Phone' = '';
    'Email' = '';
    'Role' = '';
    'Inquiry' = '';
    'Message' = '';
}

export class NewProfile {
    'content' = '';
    'uri' = '';
    'collection' = [];
}

export class Federation {
    'Society_Key' = '';
    'Company_Name' = '';
    'Society_Type' = '';
    'Primary_Web_Site' = '';
    'Address': Address = new Address();
    'profiles':  Person[] = [];
    'events': Event[] = [];
}

export class Address {
    'line_1' = '';
    'line_2' = '';
    'line_3' = '';
    'city' = '';
    'state' = '';
    'zip' = '';
}

export class Person {
    'primary_contact' = 0;
    'me_number' = '';
    'First_Name' = '';
    'Middle_Name' = '';
    'Last_Name' = '';
    'Suffix' = '';
    'Job_Title' = '';
    'phone': Phone[] = [];
    'email' = '';
    'fax' = '';

}

export class Event {
    'Event_Type' = '';
    'Event_Date' = '';
    'Event_Month' = '';
    'Event_Location' = '';
    'Event_Venue' = '';
}

export class Phone {
    'phone_type' = '';
    'phone_number' = '';
    'phone_ext' = '';
    'errors' = '';
    'dirty' = false;
}

@Injectable()
export class UserDetails {
    'tamId' = '';
    'tamIdOrg' = '';
    'email' = '';
    'givenName' = '';
    'surname' = '';
    'displayName' = '';
    'username' = '';
    'groups'? = [];

}