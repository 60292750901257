import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-landing',
  templateUrl: './admin-landing.component.html',
  styleUrls: ['./admin-landing.component.css']
})
export class AdminLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    console.log('On init called.');
    const path = window.location.pathname;
    let docelement;
    console.log(path);
    if (path.indexOf('create') > -1) {
      docelement = document.getElementById('create');
      docelement.classList.add('active');
      console.log('active class added to create');
    } else if (path.indexOf('manage') > -1 || path.indexOf('edit') > -1) {
      docelement = document.getElementById('manage');
      docelement.classList.add('active');
      console.log('active class added to manage');
    } else if (path.indexOf('report') > -1) {
      docelement = document.getElementById('report');
      docelement.classList.add('active');
      console.log('active class added to report');
    }
  }

}
