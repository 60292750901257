import { Injectable, OnInit } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { UserDetails } from "app/services/search/search-results";
import { UserInfoService } from "app/services/user-info.service";
import { Observable } from "rxjs";
import { map, last } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  user: UserDetails;
  groups: string[];
  constructor(private userService: UserInfoService, private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.getUserGroups().pipe(
      map((serviceResults: string[]) => {
        this.groups = serviceResults;
        if (this.groups[0] === "Not Authenticated") {
          window.location.href = `/login?redirect=${location.host}`;
          return false;
        } else if (this.groups.indexOf("FD Admin") > -1) {
          return true;
        }
        this.router.navigate(["federations/unauthorized"]);
        return false;
      })
    );
  }
}
