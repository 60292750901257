import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../services/search/search.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() total: number;
  pagenum = 0;
  pages = 1;
  isBackDisabled = false;
  isForwardDisabled = false;
  numbers: number[] = [];
  minIndex = 0;
  maxIndex = 5;
  admin = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.route.params.subscribe(params => {
        this.pagenum = +params['pagenumber'];
    });
    // tslint:disable-next-line:curly
    if (this.total > 0)
        this.pages = Math.ceil(this.total / 15);

     // tslint:disable-next-line:one-line
     if(window.location.href.includes('admin')){
        this.admin=true;
        this.pages = Math.ceil(this.total / 1500);
    }
    this.numbers = Array(this.pages);
    this.numbers = this.numbers.fill(1).map((x, i) => (i + 1));
    this.displayPages();
    this.isBackDisabled = this.pagenum === 1;
    this.isForwardDisabled = this.pagenum === this.pages;


}

page(pageNumber) {
    this.router.navigate(this.searchService.buildURL(undefined, undefined, pageNumber, undefined, this.admin));
    window.scrollTo(0, 0);
}

first() {
    this.router.navigate(this.searchService.buildURL(undefined, undefined, 1, undefined, this.admin));
    window.scrollTo(0, 0);
}
/*  method that is triggered when the user clicks the forward button.  Reroutes using the current page number + 1 to provide the new view.*/
forward() {
    this.router.navigate(this.searchService.buildURL(undefined, undefined, this.pagenum + 1, undefined, this.admin));
    window.scrollTo(0, 0);
}

/* method that is triggered when the user clicks the back button.  Reroutes using the current page number - 1 to provide the new view.*/
back() {
    this.router.navigate(this.searchService.buildURL(undefined, undefined, this.pagenum - 1, undefined, this.admin));
    window.scrollTo(0, 0);
}

last() {
    this.router.navigate(this.searchService.buildURL(undefined, undefined, this.pages, undefined, this.admin));
    window.scrollTo(0, 0);
}

displayPages() {
    if (this.pagenum === 1 || this.pagenum === 2 || this.pagenum === 3) {
        this.minIndex = 0;
        this.maxIndex = 5;
    } else if (this.pagenum === this.pages || this.pagenum === this.pages - 1 || this.pagenum === this.pages - 2) {
        this.minIndex = this.pages - 5;
        this.maxIndex = this.pages;
    } else {
        this.minIndex = this.pagenum - 3;
        this.maxIndex = this.pagenum + 2;
    }
}

}
