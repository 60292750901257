import { Component, OnInit } from '@angular/core';
import { SearchService } from '../services/search/search.service';
import { Router } from '@angular/router';
import { ContactInfo } from '../services/search/search-results';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  inquirytype: string[] = ['General', 'Change Request', 'Other' ];
  contact: ContactInfo = new ContactInfo();
  filteredList: string[] = [];
  filtered = false;
  prevURL = '/federations';

  constructor( private router: Router, private searchService: SearchService, private location: Location) { }

  addLabel(forItem: string, valid?: boolean) {
    $(`label[for='${forItem}'] `).addClass('show');

    if (valid !== undefined) {
      if (!valid) {
        $(`#${forItem}`).attr('class', 'valid');
      } else {
        $(`#${forItem}`).attr('class', 'error');
      }
    }
  }

  removeWatermark(component: string, valid?: boolean) {
    $(`label[for='${component}'] `).addClass('show');

    if (valid) {
      $(`#${component}`).attr('class', 'error');
    } else {
      $(`#${component}`).attr('class', 'valid');
    }
  }

  submit() {
    let text =
    `mailto:Federation.Directory@ama-assn.org?subject=${encodeURIComponent(this.contact.Inquiry)}%20Inquiry%20&body=Inquiry%20Details:`;
    text += this.createBody('First name');
    text += this.createBody('Last name');
    text += this.createBody('Phone');
    text += this.createBody('Email');
    text += this.createBody('Role');
    text += this.createBody('Inquiry');
    text += this.createBody('Message');
    document.getElementById('mailContactUs').setAttribute('href', text);
    const x = window.location.href;
    const y = decodeURIComponent(x).split('/federations/search')[1];
    this.prevURL = y ? `/federations/search${y}` : '/federations';
    this.router.navigate([this.prevURL]);
  }


  createBody(key: string) {
    let formitem = '';
    if (this.contact[key] !== '') {
      formitem = `%0D%0A${key}:%20${encodeURIComponent(this.contact[key])}%20`;
    }
    return formitem;
  }

  ngOnInit() {
    window.scrollTo(0, 0);

  }

}
