import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingComponent } from "./landing/landing.component";
import { SearchResultsComponent } from "./search-results/search-results.component";
// import { AdminLandingComponent } from './admin-landing/admin-landing.component';
import { ReportingComponent } from "./reporting/reporting.component";
import { AdminManageComponent } from "./admin-manage/admin-manage.component";
import { CreationComponent } from "./creation/creation.component";
import { EditingComponent } from "./editing/editing.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { AdminGuard } from "./guards/admin.guard";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";

const routes: Routes = [
  { path: "", redirectTo: "federations", pathMatch: "full" },
  { path: "federations", component: LandingComponent, pathMatch: "full" },
  {
    path: "federations/search",
    redirectTo: "federations/search/*/name/1/",
    pathMatch: "prefix",
  },
  {
    path: "federations/search/:searchterm",
    redirectTo: "federations/search/:searchterm/name/1/",
    pathMatch: "prefix",
  },
  {
    path: "federations/search/:searchterm/:sorttype",
    redirectTo: "federations/search/:searchterm/:sorttype/1/",
    pathMatch: "prefix",
  },
  {
    path: "federations/search/:searchterm/:sorttype/:pagenumber",
    redirectTo: "federations/search/:searchterm/:sorttype/:pagenumber/",
    pathMatch: "prefix",
  },
  {
    path: "federations/search/:searchterm/:sorttype/:pagenumber/:facet",
    component: SearchResultsComponent,
  },
  {
    path: "federations/admin",
    redirectTo: "federations/admin/report",
    pathMatch: "full",
    canActivate: [AdminGuard],
  },
  {
    path: "federations/admin/manage",
    component: AdminManageComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "federations/admin/report",
    component: ReportingComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "federations/admin/create",
    component: CreationComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "federations/admin/manage/:searchterm/:sorttype/:pagenumber",
    redirectTo: "federations/admin/manage/:searchterm/:sorttype/:pagenumber/",
    pathMatch: "prefix",
    canActivate: [AdminGuard],
  },
  {
    path: "federations/admin/manage/:searchterm/:sorttype/:pagenumber/:facet",
    component: AdminManageComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "federations/admin/report/:searchterm/:sorttype/:pagenumber",
    redirectTo: "federations/admin/report/:searchterm/:sorttype/:pagenumber/",
    pathMatch: "prefix",

    canActivate: [AdminGuard],
  },
  {
    path: "federations/admin/report/:searchterm/:sorttype/:pagenumber/:facet",
    component: ReportingComponent,
    canActivate: [AdminGuard],
  },
  {
    path: "federations/admin/edit",
    component: EditingComponent,
    canActivate: [AdminGuard],
  },
  { path: "federations/contact", component: ContactUsComponent },
  {
    path: 'federations/unauthorized',
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
