import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { LandingComponent } from './landing/landing.component';
import { SearchComponent } from './search/search.component';
import { FormsModule } from '@angular/forms';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { FacetComponent } from './facet/facet.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AdminLandingComponent } from './admin-landing/admin-landing.component';
import { ReportingComponent } from './reporting/reporting.component';
import { CreationComponent } from './creation/creation.component';
import { AdminManageComponent } from './admin-manage/admin-manage.component';
import { EditingComponent } from './editing/editing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    SearchComponent,
    AdvancedSearchComponent,
    FacetComponent,
    SearchResultsComponent,
    PaginationComponent,
    AdminLandingComponent,
    ReportingComponent,
    CreationComponent,
    AdminManageComponent,
    EditingComponent,
    ContactUsComponent,
    UnauthorizedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
