import { Component, OnInit } from "@angular/core";
import { UserDetails } from "app/services/search/search-results";
import { UserInfoService } from "app/services/user-info.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  user: UserDetails;
  mainNavStateVisible = false;
  userNavStateVisible = false;
  host: string;
  constructor(private userService: UserInfoService) {}

  ngOnInit() {
    this.host = location.host;
    this.getUserInfo();
  }

  getUserInfo(): void {
    if (!this.user) {
      this.userService.getUserInfo().subscribe((serviceResults) => {
        if (serviceResults.givenName !== undefined) {
          this.user = serviceResults;
        }
      });
    }
  }

  hideDropDown() {
    this.mainNavStateVisible = false;
  }
}
