import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { NewProfile } from './search-results';
import { map, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  searchUrl = '';
  searchTerm = '*';
  uncombinedSearchTerm = '*';
  sort = 'name';
  pageNum = 1;
  facetQuery = '';
  Url: string;

  constructor(private http: HttpClient, private route: ActivatedRoute) { }

  getResults(searchterm: string, sorttype: string, pagenumber: number, facet: string, admin: boolean) {
    this.searchTerm = searchterm;
    this.sort = sorttype;
    this.pageNum = pagenumber;
    this.facetQuery = facet;
    if (this.facetQuery) {
      this.facetQuery = this.facetQuery.replace('%28', '(').replace('%29', ')');
      this.searchTerm += ' AND ' + this.facetQuery;
    } else if (!admin) {
      this.searchTerm += ` AND SSS:0`;
    }
    this.searchUrl = `/search/${this.searchTerm}/${this.sort}/${this.pageNum}/${admin}`;
    return this.http.get(this.searchUrl).pipe(
      map(results => {
        for (const result of results['results']) {
          let stringofelements = '';
          for (const extracted of result['extracted']['content']) {
            const temp = JSON.stringify(extracted);
            stringofelements += `${temp.substring(1, temp.length - 1)},`;
          }
          result['extracted']['content'] = JSON.parse(`{${stringofelements.substring(0, stringofelements.length - 1)}}`);
        }
        return results;
      }), catchError(this.handleError)
    );
  }


  getSuggestions(searchterm: string): Observable<string[]> {
    this.searchUrl = `/suggest/${searchterm}`;
    return this.http.get(this.searchUrl).pipe(
      map(rawResults => {
        const parsedResults: string[] = [];
        for (let i = 0; i < rawResults['suggestions'].length; i++) {
          let temp = rawResults['suggestions'][i];
          temp = temp.replace(/\*/g, '');
          temp = temp.replace('"', '');
          parsedResults.push(temp);
        }
        return parsedResults;
      }),
      catchError(this.handleError)
    );
  }

  getFacetValues() {
    this.searchUrl = `/facets`;
    return this.http.get(this.searchUrl).pipe(catchError(this.handleError));
  }

  getDetails(href: string) {
    this.searchUrl = `/document?uri=${href}`;
    return this.http.get(this.searchUrl).pipe(catchError(this.handleError));
  }



  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    if (error['status'] === 200) {
      error['ok'] = true;
      return Promise.resolve(error);
    }
    return Promise.reject(error.message || error);
  }

  buildURL(searchTermParam?: string, sortParam?: string, pageNumParam?: number, facetQueryParam?: string, admin?: boolean): any[] {

    if (searchTermParam !== undefined) {
      this.uncombinedSearchTerm = searchTermParam;
    }
    if (sortParam !== undefined) {
      this.sort = sortParam;
    }
    if (pageNumParam !== undefined) {
      this.pageNum = pageNumParam;
    }
    if (facetQueryParam !== undefined) {
      this.facetQuery = facetQueryParam;
    }
    if (this.uncombinedSearchTerm === '') {
      this.uncombinedSearchTerm = '*';
    }
    if (admin) {
      const location = window.location.href;
      if (location.includes('manage')) {
        return [`federations/admin/manage`, this.uncombinedSearchTerm, this.sort, this.pageNum, this.facetQuery];
      } else if (location.includes('report')) {
        return [`federations/admin/report`, this.uncombinedSearchTerm, this.sort, this.pageNum, this.facetQuery];
      } else {
        return [`federations/admin/publish`];
      }
    } else {
      return [`federations/search`, this.uncombinedSearchTerm, this.sort, this.pageNum, this.facetQuery];
    }
  }
  createProfile(profile: NewProfile): Observable<string[]> {
    this.Url = `/documentcreate`;
    return this.http.post(this.Url, profile).pipe(
      map(rawResults => {
        return rawResults;
      }),
      catchError(this.handleError)
    );
  }
  removeProfile(profile: NewProfile): Observable<string[]> {
    this.Url = `/remove`;
    return this.http.post(this.Url, profile).pipe(
      map(rawResults => {
        return rawResults;
      }),
      catchError(this.handleError)
    );
  }
}
