import { Component, OnInit } from '@angular/core';
import { SearchComponent } from '../search/search.component';
import { SearchService } from '../services/search/search.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

import { Observable } from 'rxjs';
import { NewProfile } from '../services/search/search-results';

@Component({
  selector: 'app-admin-manage',
  templateUrl: './admin-manage.component.html',
  styleUrls: ['./admin-manage.component.css']
})
export class AdminManageComponent implements OnInit {
  searchItem = '*';
  displayFacetString: string = '';
  results;
  sort = 'name';

  constructor( private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService) { }

  getResults(searchterm: string, sorttype: string, pagenumber: number, facet: string): void {
    this.searchItem = searchterm;
    this.searchService.getResults(searchterm, sorttype, pagenumber, facet, true).subscribe(serviceResults => {
      this.results = serviceResults;
    });
  }

  edit(href: string) {
    this.router.navigate([`federations/admin/edit`], { queryParams: { 'uri': href } });
  }
  
  delete(href: string, company: string) {
    const newProfile = new NewProfile();
    const removal = confirm(`${company}'s profile will be deleted from the Federations Database. Are you sure you would like to continue?`);
    if (removal) {
        newProfile.uri = href;
        this.searchService.removeProfile(newProfile).subscribe(serviceResults => {
            const results = serviceResults;
            if (results['ok'])
                this.router.navigate([`/federations/admin`]);
          else alert(`Something went wrong. Please try again.`)
        });
    }
  }

  sortSearch(sorttype) {
    this.router.navigate(this.searchService.buildURL(undefined, sorttype, 1, undefined, true));
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log('In Admin manage component.');
      if (params['searchterm']) {
        console.log('has search term');
        this.getResults(params['searchterm'], params['sorttype'], +params['pagenumber'], params['facet']);
        this.searchItem = params['searchterm'];
        this.displayFacetString = params['facet'];
        this.sort = params['sorttype'];
      }
    });
  }

}
