import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { SearchService } from '../services/search/search.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.css']
})
export class AdvancedSearchComponent implements OnInit, AfterViewInit {
  @Input() query;
  advancedResults;
  hideClearBtn = true;
  text = 'Search federations';
  disabled = false;
  selectedFacets = { searchterm: '', SocietyType: [], State: [], EventMonth: [], EventYear: [], JobTitle: [] };
  subTopics: string[] = [];
  selectedSocietyTypes;
  selectedStates;
  selectedMonths;
  selectedYears;
  selectedJobTitles;
  constructor(private router: Router, private searchService: SearchService) { }

  ngOnInit() {
    this.searchService.getFacetValues().subscribe(serviceResults => {
      this.advancedResults = serviceResults;
      if (this.query !== undefined && this.query.length > 0) {
        this.hideClearBtn = false;
      }
    });
  }

  ngAfterViewInit() {
    console.log('Service being called.');
  }

  filter() {
    if (this.query.length === 0) {
      this.hideClearBtn = true;
    } else {
      this.hideClearBtn = false;
    }

  }

  pushOrSplice(facetArray, event, q) {
    const index = facetArray.indexOf(q);
    if (event.target.checked) {
      if (index === -1) {
        facetArray.push(q);
      }
    } else {
      if (index !== -1) {
        facetArray.splice(index, 1);
      }
    }
  }


  // Combine Facets
  combineFacets(): string {
    const facetArray: string[] = [];
    if (this.disabled) {
          facetArray.push('SSS:0');
    }
    if (this.selectedFacets.SocietyType.length > 0) {
      facetArray.push(this.buildFacetQuery(this.selectedFacets.SocietyType));
    }
    if (this.selectedFacets.State.length > 0) {
      facetArray.push(this.buildFacetQuery(this.selectedFacets.State));
    }
    if (this.selectedFacets.EventMonth.length > 0) {
      facetArray.push(this.buildFacetQuery(this.selectedFacets.EventMonth));
    }
    if (this.selectedFacets.EventYear.length > 0) {
      facetArray.push(this.buildFacetQuery(this.selectedFacets.EventYear));
    }
    if (this.selectedFacets.JobTitle.length > 0) {
      facetArray.push(this.buildFacetQuery(this.selectedFacets.JobTitle));
    }

    return (facetArray.length > 0) ? `${facetArray.join(' AND ')}` : '';
  }

  // Build facet query
  buildFacetQuery(source: string[]): string {
    let query = '';
    if (source.length > 1) {
      query += `( ${source.join(' OR ')} )`;
    } else {
      query = source[0];
    }
    return query;
  }

  advancedSearch(searchterm, selectedSocietyTypes, selectedStates, selectedMonths, selectedYears, selectedJobTitles) {
    // ($('#advancedSearchModal') as any).modal('hide');
    this.selectedFacets.SocietyType = this.fixFormat(selectedSocietyTypes, 'SocietyType');
    this.selectedFacets.EventMonth = this.fixFormat(selectedMonths, 'EventMonth');
    this.selectedFacets.EventYear = this.fixFormat(selectedYears, 'EventYear');
    this.selectedFacets.JobTitle = this.fixFormat(selectedJobTitles, 'JobTitle');
    this.selectedFacets.searchterm = this.query;
    this.router.navigate(this.searchService.buildURL(searchterm, 'name', 1, this.combineFacets(), true));
  }

  fixFormat(array, type) {
    if (array === undefined || array === null) {
      return [];
    }
    const returnArray = [];
    for (const item of array) {
      returnArray.push(`${type}:"${item}"`);
    }
    return returnArray;
  }

  // Clear facets
  clearFacets() {
    $('#advancedSearchForm').trigger('reset');
    this.query = '';
    this.hideClearBtn = true;
    this.disabled = false;
    this.selectedFacets.SocietyType = [];
    this.selectedFacets.State = [];
    this.selectedFacets.EventMonth = [];
    this.selectedFacets.EventYear = [];
    this.selectedFacets.JobTitle = [];
  }

  showModal() {
    $('#advancedSearchModal').show('fast');
  }
}
