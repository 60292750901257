import { Component, OnInit } from '@angular/core';
import { SearchComponent } from '../search/search.component';
import { SearchService } from '../services/search/search.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { NewProfile, Address } from '../services/search/search-results';
import * as FileSaver from 'file-saver';
// import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit {
  searchItem = '*';
  displayFacetString: '';
  results;
  sort = 'name';
  selectAll = false;
  profileChecked: Array<string> = [];
  d = new Date();
  date = `${this.d.getMonth()}${this.d.getDate()}${this.d.getFullYear()}`;

  constructor( private router: Router, private route: ActivatedRoute, private searchService: SearchService) { }

    ngOnInit() {
      // ensuring selectall is unnchecked after advanced search //
      this.selectAll = false;
      this.route.params.subscribe(params => {
        if (params['searchterm']) {
          this.getResults(params['searchterm'], params['sorttype'], +params['pagenumber'], params['facet']);
          this.searchItem = params['searchterm'];
          this.displayFacetString = params['facet'];
          this.sort = params['sorttype'];
        }
      });
    }

    getResults(searchterm: string, sorttype: string, pagenumber: number, facet: string): void {
      this.searchItem = searchterm;
      this.searchService.getResults(searchterm, sorttype, pagenumber, facet, true).subscribe(serviceResults => {
        this.results = serviceResults;
      });
      // ensuring selectall is unnchecked after advanced search
      this.selectAll = false;
    }
    sortSearch(sorttype) {
      this.router.navigate(this.searchService.buildURL(undefined, sorttype, 1, undefined, true));
    }
    toggle() {
      this.selectAll = !this.selectAll;
      for (const item of this.results.results) {
        this.updateChecked(item.uri, this.selectAll);
      }
    }

    updateChecked(profileChecked, eventChecked) {
      const index = this.profileChecked.indexOf(profileChecked);
      if (eventChecked) {
        if (index === -1) {
          this.profileChecked.push(profileChecked);
        }
      } else {
        if (index !== -1) {
          this.profileChecked.splice(index, 1);
        }
      }
    }

    // Contact lists for word
    wordReport(report) {
      let reportContent = '';
      let csz = '';
      // Adding check for undefined values
      for (const profile of this.results.results) {
        if (this.profileChecked.indexOf(profile.uri) > -1) {
          const addr1 = (profile.extracted.content.Address && typeof profile.extracted.content.Address !== 'undefined') ?
          (profile.extracted.content.Address.line_1 && typeof profile.extracted.content.Address.line_1 !== 'undefined') ?
          `${profile.extracted.content.Address.line_1}<br>` : '' : '' ;
          const addr2 = (profile.extracted.content.Address && typeof profile.extracted.content.Address !== 'undefined') ?
          (profile.extracted.content.Address.line_2 && typeof profile.extracted.content.Address.line_2 !== 'undefined') ?
          `${profile.extracted.content.Address.line_2}<br>` : '' : '' ;
          const addr3 = (profile.extracted.content.Address && typeof profile.extracted.content.Address !== 'undefined') ?
          (profile.extracted.content.Address.line_3 && typeof profile.extracted.content.Address.line_3 !== 'undefined') ?
          `${profile.extracted.content.Address.line_3}<br>` : '' : '' ;
          // tslint:disable-next-line:max-line-length
          if (profile.extracted.content.Address !== undefined) {
            // tslint:disable-next-line:max-line-length
            csz = `${profile.extracted.content.Address.city}, ${profile.extracted.content.Address.state} ${profile.extracted.content.Address.zip}<br>`;
          }

          let name = '';
          // tslint:disable-next-line:no-shadowed-variable
          let title = '';
          let phone = '';
          let email = '';
          let people = '';

          // Adding suffix to the Primary Contact word report
          if (profile.extracted.content.profiles !== undefined) {
          for (const person of profile.extracted.content.profiles.person) {
            if (report === 'contactbyorganization') {
              if (person.primary_contact === '1') {
                name = `${person.First_Name} ${person.Last_Name}`;
                if (person.Suffix !== undefined) {
                  name += `, ${person.Suffix}`;
                }
                if (person.Job_Title !== undefined) {
                  title = ` - ${person.Job_Title}<br>`;
                }
                if (person.phone !== undefined) {
                  phone = `${person.phone[0].phone_number}`;
                  if (person.phone[0].phone_ext !== undefined) {
                    phone += ` ext. ${person.phone[0].phone_ext}`;
                  }
                  phone += `<br>`;
                }
                if (person.email !== undefined) {
                  email = `${person.email}<br>`;
                }
              }
            } else if (report === 'BOTBackground') {
              name = (person.Suffix !== undefined) ? 
              `${person.First_Name} ${person.Last_Name}, ${person.Suffix}<br> `: 
              `${person.First_Name} ${person.Last_Name}<br> `;

              if (person.Job_Title !== undefined) {
                title = `<br><b>${person.Job_Title}</b><br>`;
              }
              if (person.phone !== undefined) {
                phone = `${person.phone[0].phone_number}`;
                if (person.phone[0].phone_ext !== undefined) {
                  phone += ` ext. ${person.phone[0].phone_ext}`;
                }
                phone += `<br>`;
              }
              if (person.email !== undefined) {
                email = `${person.email}<br>`;
              }
              people += `${title}${name}${phone}${email}`;
              name = title = phone = email = '';
            } else {
              if (this.results.qtext.indexOf(`JobTitle:"${person.Job_Title}"`) > -1) {
                name = `${person.First_Name} ${person.Last_Name} `;
                if (person.Job_Title !== undefined) {
                  title = `- ${person.Job_Title}<br>`;
                }
                if (person.phone !== undefined) {
                  phone = `${person.phone[0].phone_number}`;
                  if (person.phone[0].phone_ext !== undefined) {
                    phone += ` ext. ${person.phone[0].phone_ext}`;
                  }
                  phone += `<br>`;
                }
                if (person.email !== undefined) {
                  email = `${person.email}<br>`;
                }
              }
            }
          }
        }

          // tslint:disable-next-line:max-line-length
          if (report === 'BOTBackground') {
            reportContent +=
            `<big><b>${profile.extracted.content.Company_Name}</b></big><br>${addr1}${addr2}${addr3}${csz}${people}<br><br><br>`;
          } else {
            reportContent +=
            `${profile.extracted.content.Company_Name}<br>${name}${title}${addr1}${addr2}${addr3}${csz}${phone}${email}<br><br><br>`;
          }
        }
      }
      // tslint:disable-next-line:max-line-length
      const content = `<html xmlns='http://www.w3.org/TR/REC-html40' xmlns:office="urn:schemas-microsoft-com:office:office" xmlns:word="urn:schemas-microsoft-com:office:word"><head><xml><word:worddocument><word:view>Print</word:view><word:zoom>90</word:zoom><word:donotoptimizeforbrowser/></word:worddocument></xml><meta charset="utf-8"></head><body>${reportContent}</body></html>`;
      const title = 'Contact List';
      const doc = new Uint8Array(content.length);
      for (let i = 0; i < content.length; i++) {
        doc[i] = content.charCodeAt(i);
      }
      const blob = new Blob([doc], { type: 'text/html' });
      FileSaver.saveAs(blob, `${title}.doc`);
    }

    downloadResults(report) {
      const resultArray = [];
      resultArray.push(this.createHeading(report));
      for (const federation of this.results.results) {
        if (this.profileChecked.indexOf(federation.uri) > -1) {
          // tslint:disable-next-line:max-line-length
          resultArray.push((report === 'fullreport') ?
            this.createFullStructure(federation.extracted.content) : this.createContactStructure(federation.extracted.content, report));
        }
      }
      this.export(resultArray, `${report}-${this.date}`);

    }

    export(data, fileName): void {
      /* generate worksheet */
      data = data.filter( x => {
        return x != null;
      });
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data, { skipHeader : true});
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    }

    createHeading(report) {

      const header = {
        Company_Name: 'Federation Name',
      };

      if (report === 'fullreport') {
        header['Society_Key'] = 'Society Key';
        header['Society_Type'] = 'Society Type';
        header['line_1'] = 'Address Line 1';
        header['line_2'] = 'Address Line 2';
        header['line_3'] = 'Address Line 3';
        header['city'] = 'City';
        header['state'] = 'State';
        header['zip'] = 'Zipcode';
        header['Primary_Web_Site'] = 'Primary Website';

        for (let i = 0; i < 5; i++) {
          header[`primary_contact_${i}`] = 'Primary Contact';
          header[`me_number_${i}`] = 'ME Number';
          header[`First_Name_${i}`] = 'First Name';
          header[`Middle_Name_${i}`] = 'Middle Name';
          header[`Last_Name_${i}`] = 'Last Name';
          header[`Suffix_${i}`] = 'Suffix';
          header[`Job_Title_${i}`] = 'Job Title';
          for (let j = 0; j < 3; j++) {
            header[`phone_type${i}${j}`] = 'Phone Type';
            header[`phone_number${i}${j}`] = 'Phone Number';
          }
          header[`email_${i}`] = 'Email';
          header[`fax_${i}`] = 'Fax';
        }

        for (let i = 0; i < 6; i++) {
          header[`Event_Type_${i}`] = 'Event Type';
          header[`Event_Date_${i}`] = 'Event Date';
          header[`Event_Location_${i}`] = 'Event Location';
          header[`Event_Venue_${i}`] = 'Event Venue';
        }

      } else if (report === 'contactbytitle' || report === 'contactbyorganization') {
        header['Last_Name'] = 'Last Name';
        header['First_Name'] = 'First Name';
        header['Middle_Name'] = 'Middle Initial';
        header['Suffix'] = 'Suffix';
        header['Job_Title'] = 'Job Title';
        header['line_1'] = 'Address Line 1';
        header['line_2'] = 'Address Line 2';
        header['line_3'] = 'Address Line 3';
        header['city'] = 'City';
        header['state'] = 'State';
        header['zip'] = 'Zipcode';
        header['phone'] = 'Primary Phone Number';
        header['email'] = 'Primary Email';

      }

      return header;
    }

    // Adding check to ensure fields are not undefined, adding blank values for the undefined fields
    createFullStructure(federation) {
      const federationCreator = {
        Company_Name: (federation.Company_Name && typeof federation.Company_Name !== 'undefined') ?
        federation.Company_Name : '',
        Society_Key: (federation.Society_Key && typeof federation.Society_Key !== 'undefined') ?
        federation.Society_Key : '',
        Society_Type: (federation.Society_Type && typeof federation.Society_Type !== 'undefined') ?
        federation.Society_Type : '',
        line_1: (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.line_1 && typeof federation.Address.line_1 !== 'undefined') ? federation.Address.line_1 : '' : '' ,
        line_2: (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.line_2 && typeof federation.Address.line_2 !== 'undefined') ? federation.Address.line_2 : '' : '',
        line_3: (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.line_3 && typeof federation.Address.line_3 !== 'undefined') ? federation.Address.line_3 : '' : '',
        city: (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.city && typeof federation.Address.city !== 'undefined') ? federation.Address.city : '' : '',
        state: (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.state && typeof federation.Address.state !== 'undefined') ? federation.Address.state : '' : '',
        zip: (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.zip && typeof federation.Address.zip !== 'undefined') ? federation.Address.zip : '' : '',
        Primary_Web_Site: (federation.Primary_Web_Site && typeof federation.Primary_Web_Site !== 'undefined') ?
        federation.Primary_Web_Site : ''
      };


      for (let i = 0; i < 5; i++) {
        // Adding check to ensure federation.profiles.person is not undefined
        if (federation.profiles !== undefined && federation.profiles.person !== undefined && federation.profiles.person[i] !== undefined) {
          federationCreator[`primary_contact_${i}`] = federation.profiles.person[i].primary_contact;
          federationCreator[`me_number_${i}`] = federation.profiles.person[i].me_number;
          federationCreator[`First_Name_${i}`] = federation.profiles.person[i].First_Name;
          federationCreator[`Middle_Name_${i}`] = federation.profiles.person[i].Middle_Name;
          federationCreator[`Last_Name_${i}`] = federation.profiles.person[i].Last_Name;
          federationCreator[`Suffix_${i}`] = federation.profiles.person[i].Suffix;
          federationCreator[`Job_Title_${i}`] = federation.profiles.person[i].Job_Title;
          for (let j = 0; j < 3; j++) {
            if (federation.profiles.person[i].phone !== undefined && federation.profiles.person[i].phone[j] !== undefined) {
              federationCreator[`phone_type${i}${j}`] = federation.profiles.person[i].phone[j].phone_type;
              federationCreator[`phone_number${i}${j}`] = federation.profiles.person[i].phone[j].phone_number;
              if (federation.profiles.person[i].phone[j].phone_ext) {
                federationCreator[`phone_number${i}${j}`] += ` ext. ${federation.profiles.person[i].phone[j].phone_ext}`;
              }
            } else {
              federationCreator[`phone_type${i}${j}`] = '';
              federationCreator[`phone_number${i}${j}`] = '';
            }
          }
          federationCreator[`email_${i}`] = federation.profiles.person[i].email;
          federationCreator[`fax_${i}`] = federation.profiles.person[i].fax;
        } else {
          federationCreator[`primary_contact_${i}`] = '';
          federationCreator[`me_number_${i}`] = '';
          federationCreator[`First_Name_${i}`] = '';
          federationCreator[`Middle_Name_${i}`] = '';
          federationCreator[`Last_Name_${i}`] = '';
          federationCreator[`Suffix_${i}`] = '';
          federationCreator[`Job_Title_${i}`] = '';
          for (let j = 0; j < 3; j++) {

            federationCreator[`phone_type${i}${j}`] = '';
            federationCreator[`phone_number${i}${j}`] = '';

          }
          federationCreator[`email_${i}`] = '';
          federationCreator[`fax_${i}`] = '';
        }
      }

      for (let i = 0; i < 6; i++) {
        // Adding check to ensure federation.events.event is not undefined
        if (federation.events !== undefined && federation.events.event !== undefined && federation.events.event[i] !== undefined) {
          federationCreator[`Event_Type_${i}`] = federation.events.event[i].Event_Type;
          federationCreator[`Event_Date_${i}`] = federation.events.event[i].Event_Date;
          federationCreator[`Event_Location_${i}`] = federation.events.event[i].Event_Location;
          federationCreator[`Event_Venue_${i}`] = federation.events.event[i].Event_Venue;
        } else {
          federationCreator[`Event_Type_${i}`] = '';
          federationCreator[`Event_Date_${i}`] = '';
          federationCreator[`Event_Location_${i}`] = '';
          federationCreator[`Event_Venue_${i}`] = '';
        }
      }

      for (const item in federationCreator) {
        if (federationCreator[item] === undefined && federationCreator.hasOwnProperty(item)) {
          federationCreator[item] = '';
        }
      }

      return federationCreator;
    }

    createContactStructure(federation, report) {
      const federationCreator = {
        Company_Name: federation.Company_Name,
      };
      let byTitle;
      let byOrg;

      // tslint:disable-next-line:forin
      // Adding check for profiles not undefined
      if (federation.profiles !== undefined){
        for (const item in federation.profiles.person) {
          if (federation.profiles.person.hasOwnProperty(item)) {
            if (federation.profiles.person[item].primary_contact === '1') {
              byOrg = federation.profiles.person[item];
            }
            if (this.results.qtext.indexOf(federation.profiles.person[item].Job_Title) > -1) {
              byTitle = federation.profiles.person[item];
            }
          }
        }
      }

      const profile = (report === 'contactbyorganization' ? byOrg : byTitle);
      if (profile !== undefined) {
        federationCreator['Last_Name'] = (profile.Last_Name  && typeof profile.Last_Name !== 'undefined')
        ? profile.Last_Name : '';
        federationCreator['First_Name'] = (profile.First_Name && typeof profile.First_Name !== 'undefined')
        ? profile.First_Name : '';
        federationCreator['Middle_Name'] = (profile.Middle_Name && typeof profile.Middle_Name !== 'undefined')
        ? profile.Middle_Name : '';
        federationCreator['Suffix'] = (profile.Suffix && typeof profile.Suffix !== 'undefined')
        ? profile.Suffix : '';
        federationCreator['Job_Title'] = (profile.Job_Title && typeof profile.Job_Title !== 'undefined')
        ? profile.Job_Title : '';
        federationCreator['line_1'] = (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.line_1 && typeof federation.Address.line_1 !== 'undefined') ? federation.Address.line_1 : '' : '' ;
        federationCreator['line_2'] = (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.line_2 && typeof federation.Address.line_2 !== 'undefined') ? federation.Address.line_2 : '' : '' ;
        federationCreator['line_3'] = (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.line_3 && typeof federation.Address.line_3 !== 'undefined') ? federation.Address.line_3 : '' : '' ;
        federationCreator['city'] = (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.city && typeof federation.Address.city !== 'undefined') ? federation.Address.city : '' : '' ;
        federationCreator['state'] = (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.state && typeof federation.Address.state !== 'undefined') ? federation.Address.state : '' : '' ;
        federationCreator['zip'] = (federation.Address && typeof federation.Address !== 'undefined') ?
        (federation.Address.zip && typeof federation.Address.zip !== 'undefined') ? federation.Address.zip : '' : '' ;
        if (profile.phone !== undefined) {
          federationCreator['phone'] = profile.phone[0].phone_number;
          if (profile.phone[0].phone_ext !== undefined && profile.phone[0].phone_ext !== '') {
            federationCreator['phone'] += ` ext. ${profile.phone[0].phone_ext}`;
          }
        } else { federationCreator['phone'] = ''; }
        federationCreator['email'] = profile.email;
      } else { return; }

      for (const item in federationCreator) {
        if (federationCreator[item] === undefined  && federationCreator.hasOwnProperty(item)) {
          federationCreator[item] = '';
        }
      }

      return federationCreator;
    }

    downloadEventResults() {
      let resultArray = [];
      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        title: 'Federarion_Finder_Report.csv',
        useBom: true
      };
      for (const federation of this.results.results) {
        if (this.profileChecked.indexOf(federation.uri) > -1) {
          // tslint:disable-next-line:max-line-length
          resultArray = resultArray.concat(this.createEventStructure(federation.extracted.content));
        }
      }

      // new Angular2Csv(resultArray, `events-${this.date}`, options);
      this.export(resultArray, `events-${this.date}`);
    }

    createEventStructure(federation) {
      const federationCreator = [];

      federationCreator.push({
        Company_Name: federation.Company_Name
      });
      if (federation.events !== undefined) {
        for (const event in federation.events.event) {
          if (federation.events.event[event] !== undefined && federation.events.event.hasOwnProperty(event)) {
            const eventArray = {};
            eventArray['Event_Type'] = federation.events.event[event].Event_Type;
            eventArray['Event_Date'] = federation.events.event[event].Event_Date;
            eventArray['Event_Location'] = federation.events.event[event].Event_Location;
            eventArray['Event_Venue'] = federation.events.event[event].Event_Venue;
            federationCreator.push(eventArray);
          }
        }
        federationCreator.push({});
      } else { return []; }

      return federationCreator;
    }

    getName(result) {
      return (result && result.extracted && result.extracted.content && result.extracted.content.profiles !== ''
              && result.extracted.content.profiles !== undefined && result.extracted.content.profiles.person
              && result.extracted.content.profiles.person !== '' && result.extracted.content.profiles.person.length > 0) ?
            (result.extracted.content.profiles.person[0].First_Name + ' ' + result.extracted.content.profiles.person[0].Last_Name)
            : 'No Primary Contact';
    }

    getJobTitle(result) {
      // {{result.extracted.content.profiles.person[0].Job_Title}}
      return (result && result.extracted && result.extracted.content && result.extracted.content.profiles !== ''
              && result.extracted.content.profiles !== undefined && result.extracted.content.profiles.person
              && result.extracted.content.profiles.person !== '' && result.extracted.content.profiles.person.length > 0) ?
            result.extracted.content.profiles.person[0].Job_Title : 'No Primary Contact';
    }

}
