import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FacetService {
  facets: string[] = [];
  private facetedSearchCompleted = new Subject<string>();
  facetedSearch$ = this.facetedSearchCompleted.asObservable();
  constructor() { }

  getFacets() {
    return this.facets;
  }

  setFacets(newFacets) {
    this.facets = newFacets;
  }

  announceFacets() {
    this.facetedSearchCompleted.next('ready');
  }

}
