import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UserDetails } from './search/search-results';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(
    private http: HttpClient,
  ) { }

  // get user details
  getUserInfo() {
      const searchUrl = '/session/user';
      return this.http.get(searchUrl).pipe(
        map(rawResults => {
          if(rawResults === undefined ) {
            return;
          }
          const serviceResults = JSON.parse(JSON.stringify(rawResults));
          const parsedResults: UserDetails = new UserDetails();
          parsedResults.email = serviceResults.email;
          parsedResults.givenName = serviceResults.givenName;
          parsedResults.surname = serviceResults.surname;
          parsedResults.displayName = serviceResults.displayName;
          parsedResults.username = serviceResults.username;
          parsedResults.groups = serviceResults.groups;
          return parsedResults;
        }), catchError(this.handleError)
      );
  }

  getUserGroups() {
    const url = '/userGroups';
    return this.http.get(url).pipe(
      map(rawResults => {
        if(rawResults === undefined ) {
          return;
        }
        const serviceResults = JSON.parse(JSON.stringify(rawResults));
        return serviceResults;
    }), catchError(this.handleError)
    );
  }

  private handleError(error: any): Promise<any> {
      console.error('An error occurred', error);
      return Promise.reject(error.message || error);
  }

}
