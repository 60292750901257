import { Component, OnInit, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchService } from '../services/search/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() query = '';
  text = 'Search by Name, Affiliation, Specialty or State';
  filteredList: Array<string> = [];
  hideClearBtn = true;
  filtered = false;
  admin = false;
  constructor(private router: Router, private searchService: SearchService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['searchterm']) {
        this.query = params['searchterm'];
        if (this.query === '*') {
          this.query = '';
        } else {
          this.hideClearBtn = false;
        }
      }

      if (window.location.href.includes('admin')) {
        this.admin = true;
      }
    });
  }

  search(searchterm) {
    if (searchterm.length > 0) {
      this.filteredList = [];
      this.filtered = false;
      const url = this.searchService.buildURL(searchterm, 'name', 1, '', this.admin);
      console.log('Url : ' + url);
      this.router.navigate(url);
    }
  }

  clearSearch() {
    this.query = '';
    this.filteredList = [];
    this.filtered = false;
    document.getElementById('search-field').focus();
    this.hideClearBtn = true;

  }

  filter() {
    if (this.query.length === 0) {
      this.hideClearBtn = true;
    } else {
      this.hideClearBtn = false;
    }
    if (this.query.length > 2) {
      this.filteredList = [];
      // tslint:disable-next-line:max-line-length
      this.searchService.getSuggestions(this.query).subscribe(serviceResults => { this.filteredList = serviceResults; });
      this.filtered = true;
    } else {
      this.filtered = false;
    }
  }

  select(item) {
    this.query = item;
    this.search(item);
  }


}
