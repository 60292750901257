import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  currURL: string;
  constructor() { }

  ngOnInit() {
    this.currURL = window.location.href;
  }

}
