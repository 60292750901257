import { Component, OnInit } from '@angular/core';
import { SearchService } from '../services/search/search.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  results;
  sort = 'name';
  hideme = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService) { }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getResults(params['searchterm'], params['sorttype'], +params['pagenumber'], params['facet']);
      this.sort = params['sorttype'];
      this.hideme = {};
    });
  }

  getResults(searchterm: string, sorttype: string, pagenumber: number, facet: string): void {
    this.searchService.getResults(searchterm, sorttype, pagenumber, facet, false).subscribe(serviceResults => {
      this.results = serviceResults;
    });
  }

  sortSearch(sorttype) {
    this.router.navigate(this.searchService.buildURL(undefined, sorttype, 1, undefined, false));
  }

  toggleViewMore(index) {
    this.hideme[index] = !this.hideme[index];
  }




}
