import { Component, OnInit, ElementRef } from '@angular/core';
import { NewProfile, Federation, Person, Event, Phone, Address } from '../services/search/search-results';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SearchService } from '../services/search/search.service';

@Component({
  selector: 'app-editing',
  templateUrl: './editing.component.html',
  styleUrls: ['./editing.component.css']
})
export class EditingComponent implements OnInit {
  href = '';
  details;
  newProfile: NewProfile = new NewProfile();
  people = [];
  events = [];
  phones = [];
  Address: Address = new Address();
  dropdownValues;

  addrDef = false;
  peopleDef = false;
  eventDef = false;
  newSocietyType = '';

  constructor( private elRef: ElementRef, private router: Router, private route: ActivatedRoute,
    private searchService: SearchService, private location: Location) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.href = params['uri'];
      this.getDetails(this.href);

      this.searchService.getFacetValues().subscribe(serviceResults => {
        this.dropdownValues = serviceResults;
      });

    });
  }

  validateInput() {
    return this.details.Company_Name !== '' && this.details.Society_Key !== '' &&
     this.details.Society_Type !== undefined && this.details.Society_Type !== 'Other';
  }

  updateRecord() {

    let newRecord = this.createProfile().replace(/[\t\n]/g, '');
    // remove spaces between xml elements
    newRecord = newRecord.replace(/>[ ]{1,}/g, '>');
    this.newProfile.content = newRecord;
    // this.newProfile.uri = this.href;

    this.newProfile.uri = '/federations/' + this.details.Society_Key + '.xml';
    this.newProfile.collection = this.setCollection();
    this.searchService.createProfile(this.newProfile).subscribe(serviceResults => {
      const results = serviceResults;
      if (results['ok']) {
        this.location.back();
      } else {
        alert('Error Updating profile');
      }
    });
  }


  createProfile() {
    let orgs = '';
    // tslint:disable-next-line:max-line-length
    if ((this.people[0] !== undefined && this.people[0].Last_Name !== '') || (this.details.profiles && this.details.profiles.person[0] !== undefined && this.details.profiles.person[0].Last_Name !== '')) {
      orgs = `<profiles>`;
      for (const org of this.peopleDef ? this.details.profiles.person : this.people) {
        if (typeof org !== 'undefined' && org
          && org.Last_Name !== ''
          && org.Job_Title !== '') {
          const newOrg = this.createOrgXml(org);
          orgs = orgs + newOrg;
        }
      }
      orgs = orgs + `</profiles>`;
    }
    let events = '';
    // tslint:disable-next-line:max-line-length
    if ((this.events[0] !== undefined && this.events[0].Event_Type !== '') || (this.details.events !== undefined && this.details.events !== '' && this.details.events.event[0] !== undefined && this.details.events.event[0].Event_Type !== '')) {
      events = `<events>`;
      for (const org of this.eventDef ? this.details.events.event : this.events) {
        if (typeof org !== 'undefined' && org
          && org.Event_Type !== ''
          && org.Event_Date !== '') {
          const newEvent = this.createEventXml(org);
          events = events + newEvent;
        }
      }
      events = events + `</events>`;
    }

    // tslint:disable-next-line:max-line-length
    const web = (typeof this.details.Primary_Web_Site !== 'undefined' && this.details.Primary_Web_Site !== null && this.details.Primary_Web_Site) ?
      `<Primary_Web_Site>${this.details.Primary_Web_Site}</Primary_Web_Site>` : '';
    let addr = '';
    if (this.addrDef) {
      addr += (typeof this.details.Address.line_1 !== 'undefined' && this.details.Address.line_1 !== null && this.details.Address.line_1) ?
        `<Address><line_1>${this.details.Address.line_1}</line_1>` : '';
      addr += (typeof this.details.Address.line_2 !== 'undefined' && this.details.Address.line_2 !== null && this.details.Address.line_2) ?
        `<line_2>${this.details.Address.line_2}</line_2>` : '';
      addr += (typeof this.details.Address.line_3 !== 'undefined' && this.details.Address.line_3 !== null && this.details.Address.line_3) ?
        `<line_3>${this.details.Address.line_3}</line_3>` : '';
      addr += (typeof this.details.Address.city !== 'undefined' && this.details.Address.city !== null && this.details.Address.city) ?
        `<city>${this.details.Address.city}</city>` : '';
      addr += (typeof this.details.Address.state !== 'undefined' && this.details.Address.state !== null && this.details.Address.state) ?
        `<state>${this.details.Address.state}</state>` : '';
      addr += (typeof this.details.Address.zip !== 'undefined' && this.details.Address.zip !== null && this.details.Address.zip) ?
        `<zip>${this.details.Address.zip}</zip></Address>` : '';
    } else {
      addr += (typeof this.Address.line_1 !== 'undefined' && this.Address.line_1 !== null && this.Address.line_1) ?
        `<Address><line_1>${this.Address.line_1}</line_1>` : '';
      addr += (typeof this.Address.line_2 !== 'undefined' && this.Address.line_2 !== null && this.Address.line_2) ?
        `<line_2>${this.Address.line_2}</line_2>` : '';
      addr += (typeof this.Address.line_3 !== 'undefined' && this.Address.line_3 !== null && this.Address.line_3) ?
        `<line_3>${this.Address.line_3}</line_3>` : '';
      addr += (typeof this.Address.city !== 'undefined' && this.Address.city !== null && this.Address.city) ?
        `<city>${this.Address.city}</city>` : '';
      addr += (typeof this.Address.state !== 'undefined' && this.Address.state !== null && this.Address.state) ?
        `<state>${this.Address.state}</state>` : '';
      addr += (typeof this.Address.zip !== 'undefined' && this.Address.zip !== null && this.Address.zip) ?
        `<zip>${this.Address.zip}</zip></Address>` : '';
    }

    let sss = 0;
    if (this.details.Society_Type === 'Specialty and Service Society' || this.newSocietyType === 'Specialty and Service Society') {
      sss = 1;
    }

    const newRecord = `<federations>
        <Society_Key>${this.details.Society_Key}</Society_Key>
        <Company_Name>${this.details.Company_Name}</Company_Name>
        <Society_Type>${this.details.Society_Type === 'Other' ? this.newSocietyType : this.details.Society_Type}</Society_Type>
        <sss>${sss}</sss>
        ${web}${addr}
        ${orgs}${events}
        </federations>
        `;
    return newRecord;
  }

  reset() {
    this.dropdownValues = {};
    this.searchService.getFacetValues().subscribe(serviceResults => {
      this.dropdownValues = serviceResults;
    });
    this.getDetails(this.href);
    this.people = [];
    this.events = [];
    this.phones = [];
    this.addrDef = false;
    this.peopleDef = false;
    this.eventDef = false;
  }


  setCollection() {
    const collection = ['federation', this.details.Society_Type];
    return collection;
  }

  createOrgXml(orgPos: Person) {
    const first = (typeof orgPos.First_Name !== 'undefined' && orgPos.First_Name !== null && orgPos.First_Name) ?
      `<First_Name>${orgPos.First_Name}</First_Name>` : '';
    const middle = (typeof orgPos.Middle_Name !== 'undefined' && orgPos.Middle_Name !== null && orgPos.Middle_Name) ?
      `<Middle_Name>${orgPos.Middle_Name}</Middle_Name>` : '';
    const last = (typeof orgPos.Last_Name !== 'undefined' && orgPos.Last_Name !== null && orgPos.Last_Name) ?
      `<Last_Name>${orgPos.Last_Name}</Last_Name>` : '';
    const suffix = (typeof orgPos.Suffix !== 'undefined' && orgPos.Suffix !== null && orgPos.Suffix) ?
      `<Suffix>${orgPos.Suffix}</Suffix>` : '';
    const me_num = (typeof orgPos.me_number !== 'undefined' && orgPos.me_number !== null && orgPos.me_number) ?
      `<me_number>${orgPos.me_number}</me_number>` : '';
    const job = (typeof orgPos.Job_Title !== 'undefined' && orgPos.Job_Title !== null && orgPos.Job_Title) ?
      `<Job_Title>${orgPos.Job_Title}</Job_Title>` : '';
    const phone = this.getPhone(orgPos.phone);
    const email = (typeof orgPos.email !== 'undefined' && orgPos.email !== null && orgPos.email) ?
      `<email>${orgPos.email}</email>` : '';
    const fax = (typeof orgPos.fax !== 'undefined' && orgPos.fax !== null && orgPos.fax) ?
      `<fax>${orgPos.fax}</fax>` : '';

    let primary;
    if (this.peopleDef) {
      primary = `<primary_contact>${orgPos.primary_contact}</primary_contact>`;
    } else {
      primary = `<primary_contact>0</primary_contact>`;
      if (this.people.indexOf(orgPos) === 0) {
        primary = `<primary_contact>1</primary_contact>`;
      }
    }

    const org = `<person>
              ${primary}${first}${middle}${last}${suffix}${me_num}${job}${phone}${email}
              ${fax}
              </person>
              `;
    return org;
  }

  getPhone(phones) {
    let phonestring = '';
    for (const phone of phones) {
      if (phone.phone_number !== '' && phone.phone_number !== undefined) {
        phonestring += `<phone><phone_type>${phone.phone_type}</phone_type><phone_number>${phone.phone_number}</phone_number>`;
        if (phone.phone_ext !== '' && phone.phone_ext !== undefined) {
          phonestring += `<phone_ext>${phone.phone_ext}</phone_ext>`;
        }
        phonestring += `</phone>`;
      }
    }
    return phonestring;
  }

  createEventXml(orgPos: Event) {
    const type = (typeof orgPos.Event_Type !== 'undefined' && orgPos.Event_Type !== null && orgPos.Event_Type) ?
      `<Event_Type>${orgPos.Event_Type}</Event_Type>` : '';
    const location = (typeof orgPos.Event_Location !== 'undefined' && orgPos.Event_Location !== null && orgPos.Event_Location) ?
      `<Event_Location>${orgPos.Event_Location}</Event_Location>` : '';
    const venue = (typeof orgPos.Event_Venue !== 'undefined' && orgPos.Event_Venue !== null && orgPos.Event_Venue) ?
      `<Event_Venue>${orgPos.Event_Venue}</Event_Venue>` : '';
    const date = (typeof orgPos.Event_Date !== 'undefined' && orgPos.Event_Date !== null && orgPos.Event_Date) ?
      `<Event_Date>${orgPos.Event_Date}</Event_Date>` : '';
    const datearray = orgPos.Event_Date.split(' ');
    const month = (typeof orgPos.Event_Date !== 'undefined' && orgPos.Event_Date !== null && orgPos.Event_Date) ?
      `<Event_Month>${datearray[0]}</Event_Month>` : '';
    const year = (typeof orgPos.Event_Date !== 'undefined' && orgPos.Event_Date !== null && orgPos.Event_Date) ?
      `<Event_Year>${datearray[datearray.length - 1]}</Event_Year>` : '';

    const org = `<event>
              ${type}${location}${venue}${date}${month}${year}
              </event>
              `;

    return org;
  }

  getDetails(href: string): void {
    this.searchService.getDetails(href).subscribe(serviceResults => {
      this.details = serviceResults.federations;
      if (this.details.profiles !== undefined && this.details.profiles.person !== undefined) {
        for (let i = 0; i < (5 - this.details.profiles.person.length); i++) {
          // tslint:disable-next-line:max-line-length
          this.people[i] = { primary_contact: 0, me_number: '', First_Name: '', Middle_Name: '', Last_Name: '', Suffix: '', phone: [], fax: '', email: '' };
        }
        this.details.profiles.person = this.details.profiles.person.concat(this.people);
        this.peopleDef = true;
        for (const profile of this.details.profiles.person) {
          if (profile['phone'] === undefined) {
            for (let i = 0; i < 3; i++) {
              this.phones[i] = { phone_type: '', phone_number: '', phone_ext: '' };
            }
            profile['phone'] = this.phones;
          } else {
            for (let i = 0; i < (3 - profile.phone.length); i++) {
              this.phones[i] = { phone_type: '', phone_number: '' , phone_ext: '' };
            }
            profile.phone = profile.phone.concat(this.phones);
          }
        }
      } else {
        for (let i = 0; i < 5; i++) {
          // tslint:disable-next-line:max-line-length
          this.people.push(new Person());
          for (let j = 0; j < 3; j++) {
            this.people[i].phone[j] = new Phone();
          }
        }
      }
      if (this.details.events !== undefined) {
        for (let i = 0; i < (6 - this.details.events.event.length); i++) {
          this.events[i] = { Event_Date: '', Event_Location: '', Event_Month: '', Event_Type: '', Event_Venue: '' };
        }
        this.details.events.event = this.details.events.event.concat(this.events);
        this.eventDef = true;
      } else {
        for (let i = 0; i < 6; i++) {
          this.events[i] = new Event();
        }
      }


      if (this.details.Address === undefined) {
        this.Address = new Address();
      } else { this.addrDef = true; }
    });
    window.scrollTo(0, 0);
  }

  removeEvent(event) {
    const index = (this.eventDef ? this.details.events.event : this.events).indexOf(event);
    (this.eventDef ? this.details.events.event : this.events).splice(index, 1);
  }

}
