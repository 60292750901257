import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchService } from '../services/search/search.service';
import { FacetService } from '../services/facet/facet.service';
@Component({
  selector: 'app-facet',
  templateUrl: './facet.component.html',
  styleUrls: ['./facet.component.scss']
})
export class FacetComponent implements OnInit {
  @Input() facets;
  societytype: string[] = [];
  state: string[] = [];
  eventmonth: string[] = [];
  facetsArray: string[] = [];
  facetDisplay = true;
  disabled = false;

  preSearch = '';
  searchItem = '';

  soc = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };
  sta = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };
  eve = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };


  constructor( private router: Router,
               private route: ActivatedRoute,
               private searchService: SearchService,
               private facetsService: FacetService) {
      facetsService.facetedSearch$.subscribe(facets => {
        this.facetsArray = this.facetsService.getFacets();
      });
    }

  ngOnInit() {
    this.facetsArray = this.facetsService.getFacets();

    this.route.params.subscribe(params => {
      const searchItemStr = 'searchItem';
      this.searchItem = params[searchItemStr];
      if (this.preSearch !== this.searchItem) {
        this.societytype = [];
        this.state = [];
        this.eventmonth = [];
        this.soc = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };
        this.sta = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };
        this.eve = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };
        this.disabled = false;
        this.facetsService.setFacets([]);
        this.facetsService.announceFacets();

      }
      const facet = params.facet;
      facet.indexOf('SSS:1') > 1 ? this.disabled = true : this.disabled = false;
    });
  }

  selected(facet, facetval) {
    if (this.facetsArray.indexOf(`${facet}:\"${facetval}\"`) > -1) {
        return true;
    } else { return false; }

  }

  clearFacets() {
      this.state = [];
      this.societytype = [];
      this.eventmonth = [];
      this.disabled = false;
      this.soc = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };
      this.sta = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };
      this.eve = { num: 5, message: 'View All', isShowMore: false, class: 'search-results--facets-container' };
      this.facetsService.setFacets([]);
      this.facetsService.announceFacets();
      this.router.navigate(this.searchService.buildURL(undefined, undefined, 1, '', false));
  }

  showMore(facet: string) {
      if (facet === 'State') {
          this.showProperties(this.sta, this.facets.State.length);
      } else if (facet === 'SocietyType') {
          this.showProperties(this.soc, this.facets.SocietyType.length);
      } else if (facet === 'EventMonth') {
          this.showProperties(this.eve, this.facets.EventMonth.length);
      }
  }

  showProperties(currentVal, amount: number) {
      if (currentVal.num === 5) {
          currentVal.num = amount;
          currentVal.message = 'Show Less';
          currentVal.isShowMore = true;
          currentVal.class = 'search-results--facets-container custom-scrollbar';
      } else {
          currentVal.num = 5;
          currentVal.message = 'View All';
          currentVal.isShowMore = false;
          currentVal.class = 'search-results--facets-container';
      }
  }

  updateChecked(facet: string, event: any) {
      const q: string = facet + ':' + '\"' + event.target.value + '\"';
      if (facet === 'State') {
          this.pushOrSplice(this.state, event, q);
      } else if (facet === 'SocietyType') {
          this.pushOrSplice(this.societytype, event, q);
      } else if (facet === 'EventMonth') {
          this.pushOrSplice(this.eventmonth, event, q);
      } else if (facet === 'SSS') {
          this.disabled = !this.disabled;
          if (this.disabled) {
          this.facetsArray.push('(SSS:0 OR SSS:1)');
          } else {
              this.facetsArray.push('SSS:0');
          }
      }
      this.facetsArray = this.societytype.concat(this.state).concat(this.eventmonth);
      this.facetsService.setFacets(this.facetsArray);
      this.facetsService.announceFacets();
      this.router.navigate(this.searchService.buildURL(undefined, undefined, 1, this.combineFacets(), false));
      this.preSearch = this.searchItem;
  }

  pushOrSplice(facetArray, event, q) {
      const index = facetArray.indexOf(q);
      if (event.target.checked) {
          if (index === -1) {
              facetArray.push(q);
          }
      } else {
          if (index !== -1) {
              facetArray.splice(index, 1);
          }
      }
  }

  combineFacets(): string {
      const facetArray: string[] = [];
      if (this.disabled) {
          facetArray.push('(SSS:0 OR SSS:1)');
      } else {
          facetArray.push('SSS:0');
      }
      if (this.state.length > 0) {
          facetArray.push(this.buildFacetQuery(this.state));
      }
      if (this.societytype.length > 0) {
          facetArray.push(this.buildFacetQuery(this.societytype));
      }
      if (this.eventmonth.length > 0) {
          facetArray.push(this.buildFacetQuery(this.eventmonth));
      }
      return (facetArray.length > 0) ? `${facetArray.join(' AND ')}` : '';
  }

  buildFacetQuery(source: string[]): string {
      let query = '';
      if (source.length > 1) {
          query += `( ${source.join(' OR ')} )`;
      } else {
          query = source[0];
      }
      if (query.includes('/')) {
          query = query.replace(/(\/)/g, '%2F');
      }
      return query;
  }

  stringCount(str): boolean {
      return str.name.length > 34 ? true : false;
  }


}
